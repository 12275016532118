<template>
  <div class="synced-canvas">
    <div class="synced-canvas__container" style="text-align: center">
      <canvas
        ref="canvas_remote"
        class="synced-canvas__canvas"
        :width="width"
        :height="height"
        :style="remoteCanvasStyle"
      />
    </div>
    <div class="synced-canvas__container">
      <canvas
        ref="canvas_local"
        class="synced-canvas__canvas synced-canvas__canvas--local"
        :width="width"
        :height="height"
        :style="localCanvasStyle"
        @mousedown="onMouseDown"
        @touchstart="onMouseDown"
        @mouseup="onMouseUp"
        @touchend="onMouseUp"
        @mouseleave="onMouseLeave"
        @mouseenter="onMouseEnter"
        @mousemove="onMouseMove"
        @touchmove="onMouseMove"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import simplify from "simplify-js"
import drawLine from "@shared/helpers/draw-canvas-line"

export default Vue.extend({
  name: "Drawable",
  props: {
    simplifcationTolerance: {
      type: Number,
      default: 2
    },
    points: Object,
    lineColorRgb: String,
    lineWidth: Number,
    canDraw: Boolean,
    pen: Boolean,
    alpha: [Number, String],
    width: Number,
    height: Number,
    bgImageUrl: String,
    bgColor: {
      type: String,
      default: "#9e9e9e"
    }
  },
  data() {
    return {
      localPoints: [],
      isMouseDown: false,
      canvasCtx: null,
      canvasLocalCtx: null
    }
  },
  computed: {
    localCanvasStyle() {
      return {
        opacity: this.pen ? this.alpha : 1
      }
    },
    remoteCanvasStyle() {
      return {
        backgroundColor: this.bgColor,
        ...(this.bgImageUrl && { backgroundImage: `url(${this.bgImageUrl})` })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.canvasCtx = this.$refs.canvas_remote.getContext("2d")
      this.canvasLocalCtx = this.$refs.canvas_local.getContext("2d")
      this.$emit("ready", [this.$refs.canvas_remote, this.$refs.canvas_local])
    })
  },
  watch: {
    points: {
      handler(newValue) {
        const ctx = this.canvasCtx
        if (!ctx) return console.log("The canvas is still null")
        ctx.clearRect(0, 0, this.width, this.height)
        if (!newValue) return
        Object.values(newValue).forEach(({ data }) => {
          try {
            const { lineWidth, lineColor, points } = JSON.parse(data)
            drawLine({ lineWidth, lineColor, points, ctx }, true)
          } catch (e) {
            return console.log(e)
          }
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onMouseLeave() {
      this.isMouseOver = false
      this.isMouseDown = false
      this.onMouseUp()
    },
    onMouseEnter() {
      this.isMouseOver = true
    },
    onMouseUp(e) {
      e?.preventDefault()
      if (this.isMouseOver && this.canDraw) this.plotThePoint(e)
      this.isMouseDown = false
      if (!this.localPoints.length) return

      const points = simplify(
        this.localPoints.map(([x, y]) => ({ x, y })),
        this.simplifcationTolerance
      ).map(obj => [obj.x, obj.y])

      this.$emit("mouseUp", {
        lineColor: this.pen ? this.lineColorRgb : null,
        points,
        lineWidth: this.lineWidth
      })

      this.canvasLocalCtx.clearRect(0, 0, this.width, this.height)
      this.localPoints = []
    },
    onMouseDown(e) {
      e?.preventDefault()
      this.$emit("mouseDown")
      this.isMouseOver = true
      this.isMouseDown = true
    },
    plotThePoint(e) {
      const canvasWidth = this.$refs.canvas_local.clientWidth
      // calculate the diff between the actual canvas sizes and
      // the displayed one
      // calc a scalar to map the actual mouse x y to the right ones
      const scale = this.width / canvasWidth
      // cross-browser canvas coordinates
      let x
      let y
      if (e instanceof MouseEvent) {
        x = e.offsetX || e.layerX - e.offsetLeft
        y = e.offsetY || e.layerY - e.offsetTop
      } else {
        // TODO(Andrew): cache it
        const rect = this.$refs.canvas_local.getBoundingClientRect()
        x =
          (e.touches?.[0]?.clientX || e.changedTouches?.[0]?.clientX) -
          rect.left
        y =
          (e.touches?.[0]?.clientY || e.changedTouches?.[0]?.clientY) - rect.top
      }
      // apply the scalar
      const newX = Math.round(x * scale)
      const newY = Math.round(y * scale)
      const lineColor = this.pen ? this.lineColorRgb : this.bgColor
      this.localPoints.push([newX, newY])
      drawLine({
        lineWidth: this.lineWidth,
        lineColor: lineColor,
        points: this.localPoints,
        ctx: this.canvasLocalCtx,
        alpha: this.alpha
      })
    },
    onMouseMove(e) {
      e?.preventDefault()
      if (this.isMouseDown && this.canDraw) this.plotThePoint(e)
    }
  }
})
</script>

<style lang="scss">
.synced-canvas {
  position: relative;
  width: 100%;
  height: 100%;

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
  }

  &__canvas {
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-size: contain;
    background-position: center;

    &--local {
      background-color: transparent;
    }
  }
}
</style>
