const smooth = require("smooth-polyline")

module.exports = ({ lineWidth, lineColor, points, ctx }, isSmoothed) => {
  if (!points?.length) return

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = lineColor
  ctx.lineJoin = "round"
  ctx.lineCap = "round"
  ctx.beginPath()

  ctx.globalCompositeOperation = !lineColor ? "destination-out" : "source-over"

  const maybeSmoothed = isSmoothed ? smooth(smooth(points)) : points

  maybeSmoothed.forEach((point, index, arr) => {
    const [x, y] = point
    if (index === 0) {
      ctx.moveTo(x, y)

      if (arr.length === 1) {
        ctx.lineTo(x + 0.01, y)
      }
    } else {
      ctx.lineTo(x, y)
    }
  })

  ctx.stroke()
}
