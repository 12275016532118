var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "synced-canvas" }, [
    _c(
      "div",
      {
        staticClass: "synced-canvas__container",
        staticStyle: { "text-align": "center" },
      },
      [
        _c("canvas", {
          ref: "canvas_remote",
          staticClass: "synced-canvas__canvas",
          style: _vm.remoteCanvasStyle,
          attrs: { width: _vm.width, height: _vm.height },
        }),
      ]
    ),
    _c("div", { staticClass: "synced-canvas__container" }, [
      _c("canvas", {
        ref: "canvas_local",
        staticClass: "synced-canvas__canvas synced-canvas__canvas--local",
        style: _vm.localCanvasStyle,
        attrs: { width: _vm.width, height: _vm.height },
        on: {
          mousedown: _vm.onMouseDown,
          touchstart: _vm.onMouseDown,
          mouseup: _vm.onMouseUp,
          touchend: _vm.onMouseUp,
          mouseleave: _vm.onMouseLeave,
          mouseenter: _vm.onMouseEnter,
          mousemove: _vm.onMouseMove,
          touchmove: _vm.onMouseMove,
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }